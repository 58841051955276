<template>
    <r-e-dialog :title="title" :visible.sync="dialogVisible" :show-footer="type!==0" width="800px"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex justify-between">
                <el-form-item label="活动名称" prop="activityName">
                    <el-input v-model="formPublish.activityName" :disabled="type===0" placeholder="请填写活动名称"/>
                </el-form-item>
                <el-form-item label="活动类型" prop="activityType">
                    <el-select v-model="formPublish.activityType" :disabled="type===0" placeholder="请选择活动类型">
                        <el-option v-for="item in activityTypeList" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="活动时间" prop="endTime">
                    <el-date-picker v-model="activityTime" type="daterange" :disabled="type===0" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                                    end-placeholder="结束日期" style="width: 100%;" @change="activityChange"/>
                </el-form-item>
            </div>
            <el-form-item label="活动介绍" prop="activityIntroduce">
                <el-input v-model="formPublish.activityIntroduce" type="textarea" :disabled="type===0"
                          :autosize="{ minRows: 3, maxRows: 5}" placeholder="请填写活动介绍"/>
            </el-form-item>
        </el-form>

        <div style="text-align: right;padding-right: 12px;width: 110px;">
            活动附件
        </div>
        <r-e-table class="bg-white" ref="activityTableFileRef" :data="fileTableData" :columns="tableColumn"
                   :height="300" :showPagination="false" border>
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="onPreview(row)">详情</span>
                        <span class="table-btn" v-if="type!==0" @click="removeFile(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <el-upload v-if="type!==0" :action="uploadInterfaceIp" :headers="headersToken" :accept="accept"
                   :file-list="fileTableData" :on-success="handleSuccess" :before-upload="handleBeforeUpload"
                   :on-exceed="handleExceed" :limit="5" :show-file-list="false">
            <el-button size="small" type="primary">＋上传附件</el-button>
        </el-upload>
    </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageWarning} from "@custom/message";
import {saveAticity, getAticityInfo, updateAticity} from "@/api/activity-management";
import {baseConfig} from "@/utils/config";
import {tokenCookies} from "@storage/cookies";
import {createFullImageUrl} from "@/components/Upload";
import {timeFormat} from "@/utils/custom";

export default {
    name: "dialog-save-activity",
    data() {
        return {
            title: '',
            dialogVisible: false,
            formPublish: {
                activityName: null,
                activityType: null,
                endDate: null,
                startDate: null,
                activityIntroduce: null,
                status: 0,
            },
            activityTypeList: [],
            rules: {
                activityName: [{required: true, message: '请输入活动名称', trigger: 'blur'}],
                activityType: [{required: true, message: '请选择活动类型', trigger: 'change'}],
                endDate: [{required: true, message: '请选择活动时间', trigger: 'change'}],
                activityIntroduce: [{required: true, message: '请输入活动介绍', trigger: 'blur'}],
            },
            activityTime: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            fileTableData: [],
            tableColumn: [
                {
                    type: "index",
                    label: "序号",
                    width: 100
                },
                {
                    prop: "name",
                    label: "文件名称",
                },
                {
                    prop: "uploadTime",
                    label: "时间",
                },
            ],
            uploadInterfaceIp: baseConfig.baseURL + "/files/upload",
            headersToken: {
                "X-Access-Token": tokenCookies.get(),
            },
            accept: '.pdf,.jpg,.png,.doc,.docx',
            type: 0,
        }
    },
    async created() {
    },
    methods: {
        async openDialog(data = null, type = 0) {
            const that = this;
            that.type = type;
            switch (type) {
                case 0:
                    that.title = '活动详情';
                    break;
                case 1:
                    that.title = '添加活动';
                    break;
                case 2:
                    that.title = '修改活动';
                    break;
            }
            this.activityTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "601100");
            if (data) {
                const {uuid} = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading(loadingOptions);
                getAticityInfo({uuid}).then(res => {
                    const {info} = res;
                    if (info) {
                        const {
                            activityIntroduce, activityName, activityType, fileList, uuid, startDate, endDate, status
                        } = info;
                        that.formPublish.activityIntroduce = activityIntroduce;
                        that.formPublish.activityName = activityName;
                        that.formPublish.activityType = activityType;
                        that.formPublish.startDate = timeFormat(new Date(startDate));
                        that.formPublish.endDate = timeFormat(new Date(endDate));
                        that.formPublish.status = status;
                        that.formPublish.uuid = uuid;
                        that.activityTime = [that.formPublish.startDate, that.formPublish.endDate];
                        that.fileTableData = fileList;
                    }
                    that.dialogVisible = true;
                }).finally(() => loading.close());
            } else {
                that.dialogVisible = true;
            }
        },
        activityChange(value) {
            if (value && value.length > 0) {
                const [startDate, endDate] = value;
                this.formPublish.startDate = startDate;
                this.formPublish.endDate = endDate;
            } else {
                this.formPublish.startDate = null;
                this.formPublish.endDate = null;
            }
        },
        handleSubmitPublish() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const type = that.type;
                    const {
                        activityName, activityType, startDate, endDate, activityIntroduce, status, uuid
                    } = that.formPublish;
                    const fileList = that.fileTableData;
                    const paramData = {
                        activityName, activityType, startDate, endDate, activityIntroduce, status, fileList
                    };

                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                    if (type === 2) {
                        paramData.uuid = uuid;
                        updateAticity(paramData).then(res => {
                            MessageSuccess("修改成功");
                            that.handleCancel();
                        }).finally(() => loading.close());
                    } else {
                        saveAticity(paramData).then(res => {
                            MessageSuccess("添加成功");
                            that.handleCancel();
                        }).finally(() => loading.close());
                    }
                } else MessageWarning("请填写必填值", 1000);
            });
        },
        handleCancel() {
            this.dialogVisible = false;
            this.formPublish = {
                activityName: null, activityType: null, endDate: null, startDate: null, activityIntroduce: null, status: 0,
            }
            this.fileTableData = [];
            this.activityTime = [];
            //2022/03/21 调用父组件方法 刷新未支付房租账单列表
            this.$emit('handleSearch');
        },
        handleBeforeUpload(file) {
            return new Promise((resolve, reject) => {
                const {name} = file;
                // 上传文件格式
                const acceptArray = this.accept.split(",");
                const extensionIndex = name.lastIndexOf(".");       // 后缀序列
                const extension = name.substring(extensionIndex);   // 文件后缀
                let isFileType = acceptArray.includes(extension);
                if (!isFileType) {
                    MessageWarning(`请上传${this.accept}格式的文件！`);
                    reject(false);
                }
                resolve(isFileType);
            });
        },
        handleSuccess(response, file) {
            const uploadTime = timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
            let {returnObject: {info: {uuid}}} = response;
            this.fileTableData.push({name: file.name, url: createFullImageUrl(uuid), uuid, uploadTime});
        },
        handleExceed() {
            MessageWarning(`文件总共只能上传10个，请先删除已上传的文件！`);
        },
        onPreview(file) {
            const tempwindow = window.open("", '_blank');
            tempwindow.location = file.url;
        },
        removeFile(data) {
            const {uuid} = data;
            this.fileTableData = this.fileTableData.filter(item => item.uuid !== uuid);
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>